<template>
  <div class="main-search" id="search">
    <div class="suggestion-contents" v-if="showSuggestedItems">
      <ErrorAlert class="error-alert" v-if="showErrorMessage" :message="message">No Data Found</ErrorAlert>
      <div
        class="suggestion-items"
        id="suggestion-items"
        v-for="(item, i) in searchSuggestionData"
        :key="i"
        @click="searchSuggestedItem(item)"
      >
        {{ item }}
      </div>
      <!-- <div v-if="showSearchHistroy">
        <div
          class="search-history"
          id="search-history"
          v-for="(item,i) in searchHistoryData"
          :key="i"
        >
          <div
            id="history-item"
            style="width:100%;cursor:default"
            @click="searchFromHistory(item.title)"
          >{{item.title}}</div>
          <div class="delete-item" id="del-history" @click="deleteHistoryItem(item)">x</div>
        </div>
      </div>-->
      <!-- <div class="error-place" v-if="showError"></div> -->
      <!-- <ErrorAlert class="error-alert" v-if="showError" :message="message"></ErrorAlert> -->
    </div>
    <div class="search-contents" v-else>
      <!-- <div class="titled-contain">
        <div class="searched-title" v-for="(title,index) in iteratedArr.slice(0,3)" :key="index">
          <p v-if="title.length <= 15">{{title}}</p>
          <p v-if="title.length > 15">{{title.substring(0,10) + '...'}}</p>
        </div>
      </div>-->
      <div>
        <Loading class="loader" v-if="showLoader" />
      </div>
      <div class="error-place" v-if="showError">
        <!-- <div ></div> -->
      </div>
      <ErrorAlert class="error-alert" v-if="showErrorMessage" :message="message"></ErrorAlert>
      <div class="content-container" id="searched-container">
        <div
          class="content-view"
          v-for="(item, index) in mainSearchedContents"
          :key="index"
          @click="clickHandler(item)"
        >
          <categoryCard
            :key="mainSearchedContents.length"
            :content="item"
            :displayTags="false"
            :cardType="fetchCardType(item)"
            class="card-items"
          ></categoryCard>
          <p class="content-view-title" v-if="item.title.length < 15" id="searched-container-para">{{ item.title }}</p>
          <p class="content-view-title" v-else id="searched-container-para">{{ item.title.substring(0, 15) }}...</p>
          <!-- <div class="content-details">
            <p v-if="item.title.length <= 15" class="item-title">{{item.title}}</p>
            <p
              v-if="item.title.length > 15"
              class="item-title"
            >{{item.title.substring(0,15) + '...'}}</p>
            <div class="play-btn-container" @click="getDetailsAndPlay(item)">
              <img
                class="play-btn"
                src="@/assets/icons/Ic_Play1.svg"
                @click="getDetailsAndPlay(item)"
                alt
              />
              <div class="hover-container" @click="getDetailsAndPlay(item)">
                <p class="hover-container-title">{{item.title}}</p>
                <p
                  class="hover-container-duration"
                  v-if="item.duration"
                >{{calcTimeFormat(item.duration)}}</p>
              </div>
            </div>
          </div>-->
        </div>
        <p class="content-container-more" v-if="totalSearchedContent > 15" @click="seeMore">MORE RESULTS</p>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { mapGetters } from "vuex";

export default {
  props: ["filterCategory", "searchQuery"],
  data() {
    return {
      showSuggestedItems: true,
      showSearchHistroy: null,
      showLoader: null,
      showError: false,
      showErrorMessage: false,
      searchSuggestionData: [],
      searchedContents: [],
      iteratedArr: [],
      searchHistoryData: [],
      childCat: null,
      availabilities: [],
      subscriptions: [],
      purchases: [],
      showTitle: true,
      filteredAvailabilities: [],
      pricemodel: [],
      screenSize: screen,
      localDisplayLang: null,
      mainSearchedContents: [],
      totalSearchedContent: null,
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "availabilityList", "subscriptionList", "purchaseList"]),
    // fetchCardType() {
    //   return {
    //     cardName: "Slider",
    //     type: "LANDSCAPE",
    //     height: "85px",
    //     width:'146px',
    //     playButton: { enablePlay: false }
    //   };
    // }
  },
  watch: {
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
      }
    },
    purchaseList(val) {
      if (val) {
        this.purchases = val;
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    
    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });

    
    if (this.filterCategory) {
      if (this.filterCategory.constructor === Array && this.filterCategory.length > 0) {
        this.childCat = JSON.stringify(this.filterCategory);
      }
    }

    //assigning availability list response.
    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    //assinging purchase list response.
    if (this.purchaseList !== undefined) {
      this.purchases = this.purchaseList;
    }
  },
  mounted() {
    document.getElementById("body").style.overflowY = "hidden";
    // eventBus.$on("loading", state => {
    //   // this.showLoader = state;
    //   this.showError = state;
    //   this.message = "";
    //   this.searchedContents = [];
    //   this.mainSearchedContents = [];
    // });
    eventBus.$on("show-error-alert", (payload) => {
      this.showErrorMessage = true;
      this.showError = false;
      this.message = this.$t(payload);
      this.iteratedArr = [];
      this.searchSuggestionData = [];
      this.searchedContents = [];
      this.mainSearchedContents = [];
      this.totalSearchedContent = null;
    });
    eventBus.$on("render-search-to-dom", this.showSearchedData);
    eventBus.$on("send-suggestions-response", this.showSuggestions);
    // this.searchHistory();
    eventBus.$on("for-direct-search", this.directSearch);
    eventBus.$on("search-suggestion-contents", () => {
      this.showErrorMessage = false;
    });
  },
  methods: {
    fetchCardType(content) {
      // console.log("content", content);

      return content.objecttype !== "ALBUM" && content.objecttype !== "CHANEL"
        ? {
            cardName: "Slider",
            type: "LANDSCAPE",
            height: "53px",
            width: "90px",
            playButton: { enablePlay: true, width: "25px", height: "25px" },
          }
        : {
            cardName: "Slider",
            type: "SQUARE",
            height: "85px",
            width: "146px",
            playButton: { enablePlay: true, width: "25px", height: "25px" },
          };
    },
    // suggestion data show
    // showSuggestions(payload) {
    //   this.searchSuggestionData = payload;
    //   this.showSearchHistroy = false;
    //   this.showErrorMessage = false;
    // },

    // search directly without suggestions
    // directSearch(payload) {
    //   console.log("search directly", payload);
    //   this.$emit("showInputText", payload.query);
    //   let hasVal = this.searchHistoryData.some(
    //     el => el.title === payload.query
    //   );
    //   // console.log("hasVal", hasVal);
    //   if (hasVal) {
    //     this.searchHistoryData;
    //   } else {
    //     this.searchHistoryData.push({ title: payload.query });
    //   }
    //   // console.log("new Search History", this.searchHistoryData);
    //   localStorage.setItem(
    //     "localSearch",
    //     JSON.stringify(this.searchHistoryData)
    //   );
    //   this.showSearchHistroy = false;
    //   this.showSuggestedItems = false;
    //   this.showErrorMessage = false;
    //   eventBus.$emit("search-api-call", payload);
    // },

    // get suggested items after typing
    // searchSuggestedItem(payload) {
    //   this.$emit("showInputText", payload);
    //   this.showSuggestedItems = false;
    //   this.showSearchHistroy = false;
    //   this.showError = false;
    //   this.showErrorMessage = false;
    //   let searchObj = {
    //     query: payload,
    //     displaylanguage: this.localDisplayLang,
    //   };
    //   let hasVal = this.searchHistoryData.some(el => el.title === payload);
    //   // console.log("hasVal", hasVal);
    //   console.log("searchObj", searchObj);
    //   eventBus.$emit("search-api-call", searchObj);
    //   if (hasVal) {
    //     return this.searchHistoryData;
    //   } else {
    //     this.searchHistoryData.unshift({ title: searchObj.query });
    //   }
    //   // console.log("new Search History", this.searchHistoryData);
    //   localStorage.setItem(
    //     "localSearch",
    //     JSON.stringify(this.searchHistoryData)
    //   );

    //   console.log("searchObj", searchObj);
    //   // eventBus.$emit("search-api-call", searchObj);
    // },

    // search History Data
    // searchHistory() {
    //   if (localStorage.getItem("localSearch")) {
    //     this.searchHistoryData = JSON.parse(
    //       localStorage.getItem("localSearch")
    //     );
    //     // this.searchHistoryData.reverse();
    //     this.showSearchHistroy = true;
    //     // console.log("searchHistoryData", this.searchHistoryData);
    //   }
    // },

    // // search from history
    // searchFromHistory(val) {
    //   let searchObj = {
    //     query: val,
    //     displaylanguage: this.localDisplayLang,
    //     category: this.childCat ? this.childCat : ""
    //   };
    //   // console.log("searched from history", searchObj);
    //   this.$emit("showInputText", searchObj.query);
    //   this.showSearchHistroy = false;
    //   this.showSuggestedItems = false;
    //   this.searchHistoryData = this.searchHistoryData.filter(el => {
    //     console.log("el", el);
    //     return searchObj.query !== el.title;
    //   });
    //   console.log("before searchHistoryData", this.searchHistoryData);
    //   let sampleObj = { title: searchObj.query };
    //   this.searchHistoryData.splice(0, 0, sampleObj);
    //   console.log("after searchHistoryData", this.searchHistoryData);
    //   localStorage.setItem(
    //     "localSearch",
    //     JSON.stringify(this.searchHistoryData)
    //   );
    //   eventBus.$emit("search-api-call", searchObj);
    // },

    // delete from history
    // deleteHistoryItem(val) {
    //   // console.log("delete item", val);
    //   this.searchHistoryData = this.searchHistoryData.filter(el => el !== val);
    //   localStorage.setItem(
    //     "localSearch",
    //     JSON.stringify(this.searchHistoryData)
    //   );
    //   // this.searchHistory();
    // },

    // show searched Data
    showSearchedData(payload) {
      this.showSuggestedItems = false;
      this.showError = false;
      this.showSearchHistroy = false;
      this.showErrorMessage = false;
      this.searchedContents = payload.data;
      this.totalSearchedContent = payload.totalcount;
      this.mainSearchedContents = this.searchedContents.filter((el) => {
        return el.objecttype !== "SEASON";
      });
      
      if (this.mainSearchedContents.length === 0) {
        this.showErrorMessage = true;
        this.message = this.$t("No Contents Found");
      }
      
    },
    clickHandler(content) {
      eventBus.$emit("toggle-SearchInput", false);
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (screen.width > 0) {
          if(content.category == "TVSHOW"){
      
            let title = this.spacialCharEncoding(content.defaulttitle);
            this.$router.push({ name: "detailPage", params: { contentId: content.objectid.toLowerCase() , mediaTitle:title.toLowerCase(), lang: currentLanguage  } });

          } else {
            let title = this.spacialCharEncoding(content.defaulttitle);
            this.$router.push({ name: "detailPageMovie", params: { mediaTitle:title.toLowerCase(), contentId: content.objectid.toLowerCase(), lang: currentLanguage  } });
          }
      } else {
        eventBus.$emit("show-light-overlay", false);
      }
    },
    openDetailPopup(content) {
      //This statement to prevent click for trailers.
      if (content.contenttype && content.contenttype === "Trailer") return;

      if (
        content.objecttype !== "CONTENT" ||
        content.category === "MOVIE" ||
        (content.category === "TVSHOW" && content.objecttype !== "CONTENT")
      ) {
        let payload = {
          content: content,
          state: true,
        };
        eventBus.$emit("showContentDetail", payload);
        return;
      }
    },
    seeMore() {
      let searchData = this.searchQuery;
      
      if (this.$route.name !== "SearchPage") {
        //push to search route
        this.$router.push({
          name: "SearchPage",
          params: { query: searchData },
        });
      } else {
        //already in search route,just emit the query string
        eventBus.$emit("search-query-string", searchData);
      }
    },
  },
  beforeDestroy() {
    document.getElementById("body").style.overflowY = "auto";
    eventBus.$off("loading");
    eventBus.$off("show-error-alert");
    eventBus.$off("render-search-to-dom");
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    ErrorAlert: () => import(/* webpackChunkName: "erroralert" */ "@/components/Templates/ErrorAlert.vue"),
    categoryCard: () => import(/* webpackChunkName: "categoryCard" */ "@/components/Templates/categoryCard.vue"),
  },
  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./Search.scss"
</style>
